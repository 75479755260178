import { useEffect, useState, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Sidebar from './Sidebar'
import { FaTrashAlt } from "react-icons/fa";
import { BiCalendar, BiTrash } from "react-icons/bi";
export default function Customers (props) {
    const [rowData, setRowData] = useState([]);
    const [balance, setBalance]=useState('');
    const [customer, setCustomer]=useState('');
    const [status, setStatus]=useState('');
    const [sum, setSum]=useState('');
    const [ClientId, setId]= useState(null);
    const [ClientId2, setId2]= useState(null);
    const gridRef = useRef(null);
    const [columnDefs] = useState([

        { field: "Account_Name", sortable: true, filter: true, checkboxSelection: true},
         { field: "Last_Name", sortable: true, filter: true },
         { field: "Phone"},
         { field: "EMAIL", sortable: true, filter: true },
         { field: "Address", sortable: true },
         { field: "Client_Role", sortable: true, filter: true },
         {field: "Client_Act_Status"},
     ]);    
     async function getDescription(){
        const selectedNodes = gridRef.current.api.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        // console.log(selectedData)
        selectedData.map( node => setId(node.MsgId));
        const a= selectedData.map(p=>{return p.ClientId})
        selectedData.map( node => setId2(node.ClientId));
        // setId(a)
        // console.log(id)
        console.log(a)

        getBalance()
    }
   
     

    async function getBalance() {
        let url = ' http://localhost:9000/';
        let response = await fetch(url+'customers');
        // let response= await fetch('https://api-pamiran.herokuapp.com/customers')
        const people= await response.json();
        const AllNames= people.map(p => { return p.Account_Name });
        const AllBal= people.map(x => x.Balance);
        const selectedNodes = gridRef.current.api.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const balance = selectedData.map( node => setBalance(node.Balance));
        const customer=  selectedData.map( node => setCustomer(node.Account_Name));
        const status=  selectedData.map( node => setStatus(node.Status));
        const sum = AllBal.reduce((a, b) => a + b, 0);
        setSum(sum);
        console.log('sum: ',sum)
    
    }

   useEffect(() => {
    let url = ' http://localhost:9000/';
   fetch(url+'customers')
       
    //    fetch('https://api-pamiran.herokuapp.com/customers')
           .then(result => result.json())
           .then(rowData => setRowData(rowData))
   }, [rowData]);

    function refresh() {
        setSum('');
        setStatus('');
        setBalance('');
        setCustomer('');
    }
    function deleteAppointment() {
        // fetch('https://api-pamiran.herokuapp.com/customers')
        // .then(response => alert(`Your request is in the process...`),
        let url = ' http://localhost:9000/';
        // let response = await fetch(url+'customers');
        fetch (url+'customers/'+ ClientId2, {
            method:"DELETE"
        })
        // .then(
        
        //     fetch ('https://api-pamiran.herokuapp.com/customers')
        //     .then(result => result.json())
        //     .then(rowData => setRowData(rowData))
        //     .then(alert(` might not working yet! The id is ${ClientId}`)) 
        // )
      
        
     

    };



   return (
    <div className="transactions" id="outer-container">
        <h4> <a className="menu-item" href="/customersAdd">Add new Account</a></h4>
        <h4>Balance:</h4> `comming soon  ${sum}`
        <div id="trash">
            {/* <FaTrashAlt/> */}
            
            <button
                onClick={() => deleteAppointment(ClientId2)}
                type="button"
                className="p-1.5 mr-1.5 mt-1 rounded text-white bg-red-500 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <BiTrash />
            </button> 
        </div>
    
        <div id="page-wrap">
            <div className="ag-theme-alpine" style={{height: 450, width: 920}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowSelection="single"
                    // rowSelection="multiple"
                    onSelectionChanged= {getDescription}>
                </AgGridReact>
            </div>
       </div>
    </div>
  );
};

