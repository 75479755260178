import React from 'react';


import '../App.css';



export default function Footer(props){

 
    return (
    <div className="main-nav">
        {/* <BrowserRouter>
               <nav>
                    <ul className="nav-links">
                        <li>Copyright 2021 &copy;</li>
                        <li>All Rights Reserved</li>
                     
                        <li> <Link to={{ pathname:"/term" }} onClick={hide}> TERM OF USE</Link></li>
                        <li> <Link to={{ pathname:"/author" }} onClick={hide}> DESIGN BY PAYAM</Link></li>
   
                    </ul>
                </nav>
                <Switch>
                    <Route  exact path="/terms" component={Terms} />
                    <Route exact path="/author" component={Author}  />
                </Switch> 
        </BrowserRouter> */}
        {/* <p> <a href="../termofuse.html" > TERM OF USE</a></p> */}

        <nav>
                    <ul className="nav-links">
                        <li>Copyright {props.year} &copy;</li>
                        <li>All Rights Reserved</li>
                     
                        <li> <a href="../termofuse.html" > TERM OF USE</a></li>
                        <li> <a href="../author.html" > ABOUT THE AUTHOR</a></li>
   
                    </ul>
                </nav>
        </div>
    );
}
