// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getDatabase} from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
function startFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyBunSB_zXa2cZaty7PMXR7xaVsVwfc0dT4",
    authDomain: "resume-aa41a.firebaseapp.com",
    databaseURL: "https://resume-aa41a-default-rtdb.firebaseio.com",
    projectId: "resume-aa41a",
    storageBucket: "resume-aa41a.appspot.com",
    messagingSenderId: "144917382199",
    appId: "1:144917382199:web:afeb802e6df4947820ebb2",
    measurementId: "G-LM0X674LQ0"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
return getDatabase(app);
}
// export const db = getFirestore(app);
// // export const db = firebaseConfig.getFirestore();
// export const auth = getAuth(app);
// export const provider = new GoogleAuthProvider();
export default startFirebase;