import React, { Component } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import {Redirect} from "react-router-dom";
import '../App.css'
import NavAdmin from './NavAdmin';
import NavCustomer from './NavCustomer';

const CLIENT_ID ='225079078023-if27hrmk7lpsm8a6n7s828ib9luf15ss.apps.googleusercontent.com';

class GoogleBtn extends Component {
  
   constructor(props) {
    super(props);
    this.state = {
      isLogined: false,
      accessToken: '',
      givenName:'',
      img:'../images/login.png',
      email:''
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

    async login (response) {    
      if(response.accessToken){
        let isAdmin = await registerUser(response.profileObj.email);
        let isIntheList = await checkUser(response.profileObj.email);
        this.setState(state => ({
          isLogined: true,
          accessToken: response.accessToken,
          givenName:response.profileObj.givenName,
          img:response.profileObj.imageUrl,
          email:response.profileObj.email,
          isAdmin,
          isIntheList
        }));
      }
  }

  logout (response) {
    this.setState(state => ({
      isLogined: false,
      accessToken: '',
      givenName:'',
      img:'../images/login.png',
      email:''
    }));
  }

  handleLoginFailure (response) {
    alert('Failed to log in, make sure your email is registered with us! If you have difficulties please contact us at +1 206 419 1626.')
  }

  handleLogoutFailure (response) {
    alert('Failed to log out')
  }

  render() {
        return (
    <div>
      { this.state.isLogined
      ?
        this.state.isAdmin ? <>
          <GoogleLogout
            clientId={CLIENT_ID}
            buttonText='Logout'
            onLogoutSuccess={this.logout}
            onFailure={this.handleLogoutFailure} />
            < NavAdmin email={this.state.email} admin= {this.state.isAdmin} logined= {this.state.isLogined} customer={ this.state.isIntheList }/>
            </>:
             this.state.isIntheList ?
              <>
                <GoogleLogout
                  clientId={CLIENT_ID}
                  buttonText='Logout'
                  onLogoutSuccess={this.logout}
                  onFailure={this.handleLogoutFailure} />
                  <Redirect to= "/dashboard"/>
                  <NavCustomer email={this.state.email} admin= {this.state.isAdmin}  logined= {this.state.isLogined} customer={ this.state.isIntheList }/>
              </>
              : <Redirect to= "/register"/>
            : <>
              <GoogleLogin
              clientId={CLIENT_ID}
              buttonText='Login'
              onSuccess={this.login}
              onFailure={this.handleLoginFailure}
              cookiePolicy={'single_host_origin'}
              responseType='code,token' />      
            </>    
      }
      <img src={ this.state.img}
      alt=""
      />
      { this.state.givenName ? <h5>Welcome dear: <br/><br/> { this.state.givenName }</h5> : null } 
     
    </div>
    )
  }
}

async function registerUser (email) {
  let url = 'http://localhost:9000/';
  // let url = 'https://pamiran-aws-api.cqjdkxufjygk.us-west-2.rds.amazonaws.com/';
  let response = await fetch(url+'customers');
 
  const people= await response.json();
  const role = people.find((obj) => obj.Client_Role ==='admin')
  // console.log("1",role)
    return email === role.EMAIL
   
}
async function checkUser (email) {
  const userEmail= email;
  let url = 'http://localhost:9000/';
  // let url = 'https://pamiran-aws-api.cqjdkxufjygk.us-west-2.rds.amazonaws.com/';
  let response = await fetch(url+'customers');
  const people= await response.json();
  // const role = people.find((obj) => obj.EMAIL === email)
const users= people.map((user)=>user.EMAIL)
// console.log(users);
const find = users.find(user=>user===userEmail)
return find
// console.log(find)
    // find ? (<>true</> )
    //   :
    //   (<>false</>)
   
}



export default GoogleBtn;