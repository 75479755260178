function Epoxy () {
    return ( 
        <div>
           <main>
				<table  className='j'>
					<thead>
						<tr>
							<th colSpan="4"> My Epoxy Gallery</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><img title="Screen printing on tshirts" src="/videos/1.jpg" alt=" logo"/> </td>
							<td> <img title="Designing of molds" src="/videos/2.jpg" alt="the met logo"/> </td>
							<td><img title="Regular and diecut boxes" src="/videos/3.mp4" alt="the met logo"/> </td>
							<td> <img title="Screen printing on plexiglasses" src="/videos/4.mp4" alt="the met logo"/></td>
						</tr>
        			</tbody>
        		</table>
        	</main>
        </div>
     );
}
 
export default Epoxy;