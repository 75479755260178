

import React from "react";
import startFirebase from "../../../firebase";
import {ref, get,child} from "firebase/database";
import "./resume.css"

let displayIt = false;
export default  class Resume extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            db:'',
            name:'',
            phone:'',
            address:'',
            email:'',
            github:'',
            linkedin:'',
            q1:'',
            q2:'',
            q3:'',
            q4:'',
            Languages:'',
            tools:'',
            ed1:'',
            ed2:'',
            ed3:'',
            ed1sch:'',
            ed2sch:'',
            ed3sch:'',
            ed1schgpa:'',
            ed2schgpa:'',
            ed3schgpa:'',
            exp1:'',
            exp2:'',
            exp3:'',
            det1:'',
            det2:'',
            det3:'',
            p1:'',
            d1:'',
            p2:'',
            d2:'',
            p3:'',
            d3:'',
            p4:'',
            d4:'',
            p5:'',
            d5:'',
            p6:'',
            d6:'',
            job1:'',
            job2:'',
            job3:'',
            job1t:'',
            job2t:'',
            job3t:'',
            job1td:'',
            job1td2:'',
            job1td3:'',
            job2td:'',
            job2td2:'',
            job2td3:'',
            job3td:'',
            job3td2:'',
            job3td3:'',
            // displayIt:false

        }
        this.interface= this.interface.bind(this);
    }

    componentDidMount(){
        this.setState({
            db:startFirebase()
        });
    }

    render(){
        return(
            <>
            <div className="btnContainer">
                <div className="btn">
                    <button id="select" onClick={this.interface}>See the resume</button>
                </div>
                <div className ="ele">
                      <a title="Click here to download the catalog!" href='../documents/PayamTaherirostamiResume2022.pdf'>
               <img id="dnl" src="../images/dnl.png" alt="the dnllogo" /></a>
                </div>
            </div>
           {displayIt &&
              
         
           
           
            <div className="resume">
                <div className="name"><h3>{this.state.name}</h3></div>
                <div className="header">
                    <div className="address"><h4>{this.state.address}</h4></div>
                    <div className="x"></div>
                    <div className="phone"><h4>{this.state.phone}</h4></div>
                    <div className="x"></div>
                    <div className="email"><h4>{this.state.email}</h4></div>
                </div>
                    <div className="header2">
                    <div className="git"><h4>{this.state.github}</h4></div>
                    <div className="link"><h4>{this.state.linkedin}</h4></div>
                </div>
                <hr></hr>
                <div className="qualifications">
                    <h4>Summary of qualifications</h4>
                    <ul>
                        <li>{this.state.q1}</li>
                        <li>{this.state.q2}</li>
                        <li>{this.state.q3}</li>
                        <li>{this.state.q4}</li>
                    </ul>
                </div>
                <hr></hr>
                <div className="skills">
                    <h4>Technical Skills</h4>
                    <h5>Languages:</h5>
                    <div className="lang">
                        {this.state.Languages}
                    </div>
                </div>
                <div className="tools">
                    <h5>Tools:</h5>
                   
                </div>
                <div className="tool">
                    {this.state.tools}
                </div>
                <hr></hr>
                <div className="ed">
                    <h4>Education and Training</h4>
            
                            <div className="eds">
                                <h5>{this.state.ed1}</h5>
                                <div className="sch">
                                    {this.state.ed1sch}
                                </div>
                                <div className="gpas">
                                    {this.state.ed1schgpa}
                                </div>

                                <h5>{this.state.ed2}</h5>
                                <div className="sch">
                                    {this.state.ed2sch}
                                </div>
                                <div className="gpas">
                                    {this.state.ed2schgpa}
                                </div>

                                <h5>{this.state.ed3}</h5>
                                <div className="sch">
                                    {this.state.ed3sch}
                                </div>
                                <div className="gpas">
                                    {this.state.ed3schgpa}
                                </div>
                            </div>
           
                </div>
                <hr></hr>
                <div className="exp">
                        <h4>Relevant Experience</h4>
                        <ul>
                        <li> {this.state.exp1}</li> 
                      <div className="exps" >{this.state.det1}</div>
                        <li> {this.state.exp2}</li> 
                        <div className="exps" >{this.state.det2}</div>
                        <li> {this.state.exp3}</li> 
                        <div className="exps" > {this.state.det3}</div>
                        </ul>  
                </div>
                <hr></hr>
                <div className="proj">
                    <h4>Projects</h4>
                    <div className="projs">
                        <ul>
                        <li>{this.state.p6}</li>
                        {this.state.d6}
                        <p>for more details please click <i><a href="../project.html">Here</a></i></p>
                        <li>{this.state.p1}</li>
                        {this.state.d1}

                        <li>{this.state.p2}</li>
                        {this.state.d2}

                        <li> {this.state.p3}</li>
                        {this.state.d3}

                        <li> {this.state.p4}</li>
                        {this.state.d4}

                        <li> {this.state.p5}</li>
                        {this.state.d5}

                        </ul>
                    </div>
                </div>
                <hr></hr>
                <div className="work">
                    <h4>Other Work Experience</h4>
                    
                    <div className="j1">
                       
                        <div className="j1t">            
                            {this.state.job1}
                        </div>
                        <div className="j11">
                            {this.state.job1t}
                        </div>
                        <div className="j111">
                            <ul>
                            <li>{this.state.job1td}</li>
                            <li> {this.state.job1td2}</li>
                            <li>  {this.state.job1td3}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="j2">
                       
                       <div className="j2t">            
                           {this.state.job2}
                       </div>
                       <div className="j22">
                           {this.state.job2t}
                       </div>
                       <div className="j222">
                        <ul>
                        <li> {this.state.job2td}</li>
                        <li>  {this.state.job2td2}</li>
                        <li>  {this.state.job2td3}</li>
                           </ul>
                       </div>
                   </div>
                   <div className="j3">
                       
                       <div className="j3t">            
                           {this.state.job3}
                       </div>
                       <div className="j33">
                           {this.state.job3t}
                       </div>
                       <div className="j333">
                           {this.state.job3td}
                           {this.state.job3td2}
                           {this.state.job3td3}
                       </div>
                   </div>
                   </div>
             
            </div> }
            </>
        )

    }
    interface(event){
        displayIt=!displayIt;
   
        const id= event.target.id;
 
        // const id= 'select';
     if (id ==='select'){
            this.selectData();
        }
        else if (id==='dnl'){
            alert('Thanks for your interest!')
        

        }
   
        }
    
    
    getAllInfo(){
        return{
            name:this.state.name,
            phone:Number(this.state.phone),
            email:this.state.email,
            github:this.state.github,
            address:this.state.address,
            linkedin:this.state.linkedin,
            q1:this.state.q1,
            q2:this.state.q2,
            q3:this.state.q3,
            q4:this.state.q4,
            Languages:this.state.Languages,
            tools:this.state.tools,
            ed1:this.state.ed1,
            ed2:this.state.ed2,
            ed3:this.state.ed3,
            ed1sch:this.state.ed1sch,
            ed2sch:this.state.ed2sch,
            ed3sch:this.state.ed3sch,
            ed1schgpa:this.state.ed1schgpa,
            ed2schgpa:this.state.ed2schgpa,
            ed3schgpa:this.state.ed3schgpa,
            exp1:this.state.exp1,
            exp2:this.state.exp2,
            exp3:this.state.exp3,
            det1:this.state.det1,
            det2:this.state.det2,
            det3:this.state.det3,
            p1:this.state.p1,
            d1:this.state.d1,
            p2:this.state.p2,
            d2:this.state.d2,
            p3:this.state.p3,
            d3:this.state.d3,
            p4:this.state.p4,
            d4:this.state.d4,
            p5:this.state.p5,
            d5:this.state.d5,
            p6:this.state.p6,
            d6:this.state.d6,
            job1:this.state.job1,
            job2:this.state.job2,
            job3:this.state.job3,
            job1t:this.state.job1t,
            job2t:this.state.job2t,
            job3t:this.state.job3t,
            job1td:this.state.job1td,
            job1td2:this.state.job1td2,
            job1td3:this.state.job1td3,
            job2td:this.state.job2td,
            job2td2:this.state.job2td2,
            job2td3:this.state.job2td3,
            job3td:this.state.job3td,
            job3td2:this.state.job3td2,
            job3td3:this.state.job3td3,

        }
    }

selectData(){
  const dbref = ref(this.state.db);
  const username = 'Payam Taherirostami';

  const data= get(child(dbref,'Resume/Title/'+username )).then((snapshot)=>{
    if(snapshot.exists()){
      this.setState({
        name:snapshot.val().name,
        phone: snapshot.val().phone,
        email:snapshot.val().email,
        github:snapshot.val().github,
        address:snapshot.val().address,
        linkedin:snapshot.val().linkedin,
        q1:snapshot.val().q1,
        q2:snapshot.val().q2,
        q3:snapshot.val().q3,
        q4:snapshot.val().q4,
        Languages:snapshot.val().Languages,
        tools:snapshot.val().tools,
        ed1:snapshot.val().ed1,
        ed2:snapshot.val().ed2,
        ed3:snapshot.val().ed3,
        ed1sch:snapshot.val().ed1sch,
        ed2sch:snapshot.val().ed2sch,
        ed3sch:snapshot.val().ed3sch,
        ed1schgpa:snapshot.val().ed1schgpa,
        ed2schgpa:snapshot.val().ed2schgpa,
        ed3schgpa:snapshot.val().ed3schgpa,
        exp1:snapshot.val().exp1,
        exp2:snapshot.val().exp2,
        exp3:snapshot.val().exp3,
        det1:snapshot.val().det1,
        det2:snapshot.val().det2,
        det3:snapshot.val().det3,
        p1:snapshot.val().p1,
        d1:snapshot.val().d1,
        p2:snapshot.val().p2,
        d2:snapshot.val().d2,
        p3:snapshot.val().p3,
        d3:snapshot.val().d3,
        p4:snapshot.val().p4,
        d4:snapshot.val().d4,
        p5:snapshot.val().p5,
        d5:snapshot.val().d5,
        p6:snapshot.val().p6,
        d6:snapshot.val().d6,
        job1:snapshot.val().job1,
        job2:snapshot.val().job2,
        job3:snapshot.val().job3,
        job1t:snapshot.val().job1t,
        job2t:snapshot.val().job2t,
        job3t:snapshot.val().job3t,
        job1td:snapshot.val().job1td,
        job1td2:snapshot.val().job1td2,
        job1td3:snapshot.val().job1td3,
        job2td:snapshot.val().job2td,
        job2td2:snapshot.val().job2td2,
        job2td3:snapshot.val().job2td3,
        job3td:snapshot.val().job3td,
        job3td2:snapshot.val().job3td2,
        job3td3:snapshot.val().job3td3,
      })
    }
    else{
      alert("no data found")
    }
    console.log(data)
  })
  .catch((error)=>{alert("there is an error, details: "+error )})
}
}

