

import React from "react";
import startFirebase from "../../firebase";
import {ref, set, get,update,remove,child} from "firebase/database";


export default  class ResumeForm extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            db:'',
            name:'',
            phone:'',
            address:'',
            email:'',
            github:'',
            linkedin:'',
            q1:'',
            q2:'',
            q3:'',
            q4:'',
            Languages:'',
            tools:'',
            ed1:'',
            ed2:'',
            ed3:'',
            ed1sch:'',
            ed2sch:'',
            ed3sch:'',
            ed1schgpa:'',
            ed2schgpa:'',
            ed3schgpa:'',
            exp1:'',
            exp2:'',
            exp3:'',
            det1:'',
            det2:'',
            det3:'',
            p1:'',
            d1:'',
            p2:'',
            d2:'',
            p3:'',
            d3:'',
            p4:'',
            d4:'',
            p5:'',
            d5:'',
            p6:'',
            d6:'',
            job1:'',
            job2:'',
            job3:'',
            job1t:'',
            job2t:'',
            job3t:'',
            job1td:'',
            job1td2:'',
            job1td3:'',
            job2td:'',
            job2td2:'',
            job2td3:'',
            job3td:'',
            job3td2:'',
            job3td3:'',


        }
        this.interface= this.interface.bind(this);
    }

    componentDidMount(){
        this.setState({
            db:startFirebase()
        });
    }

    render(){
        return(
            <>
            <label>Name:</label>
           <input type= 'text' id="userbox" value= {this.state.name} onChange={e=>{this.setState({name: e.target.value})}}/>
           <br/>

           <label>Phone:</label>
           <input type= 'number' id="phonebox" value= {this.state.phone} onChange={e=>{this.setState({phone: e.target.value})}}/>
           <br/>

           <label>Address:</label>
           <input type= 'text' id="addbox" value= {this.state.address} onChange={e=>{this.setState({address: e.target.value})}}/>
           <br/>

           <label>Email:</label>
           <input type= 'email' id="emailbox" value= {this.state.email} onChange={e=>{this.setState({email: e.target.value})}}/>
           <br/>

           <label>gitHub:</label>
           <input type= 'text' id="githubbox" value= {this.state.github} onChange={e=>{this.setState({github: e.target.value})}}/>
           <br/>

           <label>linkedIn:</label>
           <input type= 'text' id="linkbox" value= {this.state.linkedin} onChange={e=>{this.setState({linkedin: e.target.value})}}/>
           <br/>

           <label>Qualifications:</label>
           <input type= 'text' id="qua1" value= {this.state.q1} onChange={e=>{this.setState({q1: e.target.value})}}/>
           <input type= 'text' id="qua2" value= {this.state.q2} onChange={e=>{this.setState({q2: e.target.value})}}/>
           <input type= 'text' id="qua3" value= {this.state.q3} onChange={e=>{this.setState({q3: e.target.value})}}/>
           <input type= 'text' id="qua4" value= {this.state.q4} onChange={e=>{this.setState({q4: e.target.value})}}/>
           <br/>

           <label>Languages:</label>
           <input type= 'text' id="Languages" value= {this.state.Languages} onChange={e=>{this.setState({Languages: e.target.value})}}/>
           <br/>

           <label>Tools:</label>
           <input type= 'text' id="tools" value= {this.state.tools} onChange={e=>{this.setState({tools: e.target.value})}}/>
           <br/>
        


           <label>Educations:</label>
           <input type= 'text' id="ed1" value= {this.state.ed1} onChange={e=>{this.setState({ed1: e.target.value})}}/>
           <input type= 'text' id="ed2" value= {this.state.ed2} onChange={e=>{this.setState({ed2: e.target.value})}}/>
           <input type= 'text' id="ed3" value= {this.state.ed3} onChange={e=>{this.setState({ed3: e.target.value})}}/>
           <br/>
           <label>Schools:</label>
           <input type= 'text' id="ed1sch" value= {this.state.ed1sch} onChange={e=>{this.setState({ed1sch: e.target.value})}}/>
           <input type= 'text' id="ed2sch" value= {this.state.ed2sch} onChange={e=>{this.setState({ed2sch: e.target.value})}}/>
           <input type= 'text' id="ed3sch" value= {this.state.ed3sch} onChange={e=>{this.setState({ed3sch: e.target.value})}}/>
           <br/>
           <label>GPAs:</label>
           <input type= 'text' id="ed1schgpa" value= {this.state.ed1schgpa} onChange={e=>{this.setState({ed1schgpa: e.target.value})}}/>
           <input type= 'text' id="ed2schgpa" value= {this.state.ed2schgpa} onChange={e=>{this.setState({ed2schgpa: e.target.value})}}/>
           <input type= 'text' id="ed3schgpa" value= {this.state.ed3schgpa} onChange={e=>{this.setState({ed3schgpa: e.target.value})}}/>
           <br/>

           <label>Relevant Experience:</label>
           <input type= 'text' id="exp1" value= {this.state.exp1} onChange={e=>{this.setState({exp1: e.target.value})}}/>
           <input type= 'text' id="exp2" value= {this.state.exp2} onChange={e=>{this.setState({exp2: e.target.value})}}/>
           <input type= 'text' id="exp3" value= {this.state.exp3} onChange={e=>{this.setState({exp3: e.target.value})}}/>
           <br/>
           <label>Relevant Experience details:</label>
           <input type= 'text' id="det1" value= {this.state.det1} onChange={e=>{this.setState({det1: e.target.value})}}/>
           <input type= 'text' id="det2" value= {this.state.det2} onChange={e=>{this.setState({det2: e.target.value})}}/>
           <input type= 'text' id="det3" value= {this.state.det3} onChange={e=>{this.setState({det3: e.target.value})}}/>
           <br/>
           <label>Project1:</label>
           <input type= 'text' id="p1" value= {this.state.p1} onChange={e=>{this.setState({p1: e.target.value})}}/>
           <label>Description:</label>
           <input type= 'text' id="d1" value= {this.state.d1} onChange={e=>{this.setState({d1: e.target.value})}}/>
           <br/>
           <label>Project2:</label>
           <input type= 'text' id="p2" value= {this.state.p2} onChange={e=>{this.setState({p2: e.target.value})}}/>
           <label>Description:</label>
           <input type= 'text' id="d2" value= {this.state.d2} onChange={e=>{this.setState({d2: e.target.value})}}/>
           <br/>
           <label>Project3:</label>
           <input type= 'text' id="p3" value= {this.state.p3} onChange={e=>{this.setState({p3: e.target.value})}}/>
           <label>Description:</label>
           <input type= 'text' id="d3" value= {this.state.d3} onChange={e=>{this.setState({d3: e.target.value})}}/>
           <br/>
           <label>Projec4t:</label>
           <input type= 'text' id="p4" value= {this.state.p4} onChange={e=>{this.setState({p4: e.target.value})}}/>
           <label>Description:</label>
           <input type= 'text' id="d4" value= {this.state.d4} onChange={e=>{this.setState({d4: e.target.value})}}/>
           <br/>
           <label>Project5:</label>
           <input type= 'text' id="p5" value= {this.state.p5} onChange={e=>{this.setState({p5: e.target.value})}}/>
           <label>Description:</label>
           <input type= 'text' id="d5" value= {this.state.d5} onChange={e=>{this.setState({d5: e.target.value})}}/>
           <br/>
           <label>Project6:</label>
           <input type= 'text' id="p6" value= {this.state.p6} onChange={e=>{this.setState({p6: e.target.value})}}/>
           <label>Description:</label>
           <input type= 'text' id="d6" value= {this.state.d6} onChange={e=>{this.setState({d6: e.target.value})}}/>
           <br/>
           <label>Job:</label>
           <input type= 'text' id="job1" value= {this.state.job1} onChange={e=>{this.setState({job1: e.target.value})}}/>
           <label>Job Title:</label>
           <input type= 'text' id="job1t" value= {this.state.job1t} onChange={e=>{this.setState({job1t: e.target.value})}}/>
           <label>Job Description:</label>
           <input type= 'text' id="job1td" value= {this.state.job1td} onChange={e=>{this.setState({job1td: e.target.value})}}/>
           <input type= 'text' id="job1td2" value= {this.state.job1td2} onChange={e=>{this.setState({job1td2: e.target.value})}}/>
           <input type= 'text' id="job1td3" value= {this.state.job1td3} onChange={e=>{this.setState({job1td3: e.target.value})}}/>
           <br/>
           <label>Job:</label>
           <input type= 'text' id="job2" value= {this.state.job2} onChange={e=>{this.setState({job2: e.target.value})}}/>
           <label>Job Title:</label>
           <input type= 'text' id="job2t" value= {this.state.job2t} onChange={e=>{this.setState({job2t: e.target.value})}}/>
           <label>Job Description:</label>
           <input type= 'text' id="job2td" value= {this.state.job2td} onChange={e=>{this.setState({job2td: e.target.value})}}/>
           <input type= 'text' id="job2td2" value= {this.state.job2td2} onChange={e=>{this.setState({job2td2: e.target.value})}}/>
           <input type= 'text' id="job2td3" value= {this.state.job2td3} onChange={e=>{this.setState({job2td3: e.target.value})}}/>
           <br/>
           <label>Job:</label>
           <input type= 'text' id="job3" value= {this.state.job3} onChange={e=>{this.setState({job3: e.target.value})}}/>
           <label>Job Title:</label>
           <input type= 'text' id="job3t" value= {this.state.job3t} onChange={e=>{this.setState({job3t: e.target.value})}}/>
           <label>Job Description:</label>
           <input type= 'text' id="job3td" value= {this.state.job3td} onChange={e=>{this.setState({job3td: e.target.value})}}/>
           <input type= 'text' id="job3td2" value= {this.state.job3td2} onChange={e=>{this.setState({job3td2: e.target.value})}}/>
           <input type= 'text' id="job3td3" value= {this.state.job3td3} onChange={e=>{this.setState({job3td3: e.target.value})}}/>
           <br/>

   {/* <button id="add" onClick={this.interface}>add</button> */}
   <button id="update" onClick={this.interface}>update</button>
           {/* <button id="delete" onClick={this.interface}>delete</button> */}
           <button id="select" onClick={this.interface}>select</button>
            </>
        )

    }
    interface(event){
        const id= event.target.id;
        if(id ==='add'){
            this.insertData();
        }
        else if (id ==='update'){
           this.updateData();
        }
        else if (id ==='select'){
            this.selectData();
        }
        else if (id ==='delete'){
            this.deleteData();
        }
        }
    
    
    getAllInfo(){
        return{
            name:this.state.name,
            phone:Number(this.state.phone),
            email:this.state.email,
            github:this.state.github,
            address:this.state.address,
            linkedin:this.state.linkedin,
            q1:this.state.q1,
            q2:this.state.q2,
            q3:this.state.q3,
            q4:this.state.q4,
            Languages:this.state.Languages,
            tools:this.state.tools,
            ed1:this.state.ed1,
            ed2:this.state.ed2,
            ed3:this.state.ed3,
            ed1sch:this.state.ed1sch,
            ed2sch:this.state.ed2sch,
            ed3sch:this.state.ed3sch,
            ed1schgpa:this.state.ed1schgpa,
            ed2schgpa:this.state.ed2schgpa,
            ed3schgpa:this.state.ed3schgpa,
            exp1:this.state.exp1,
            exp2:this.state.exp2,
            exp3:this.state.exp3,
            det1:this.state.det1,
            det2:this.state.det2,
            det3:this.state.det3,
            p1:this.state.p1,
            d1:this.state.d1,
            p2:this.state.p2,
            d2:this.state.d2,
            p3:this.state.p3,
            d3:this.state.d3,
            p4:this.state.p4,
            d4:this.state.d4,
            p5:this.state.p5,
            d5:this.state.d5,
            p6:this.state.p6,
            d6:this.state.d6,
            job1:this.state.job1,
            job2:this.state.job2,
            job3:this.state.job3,
            job1t:this.state.job1t,
            job2t:this.state.job2t,
            job3t:this.state.job3t,
            job1td:this.state.job1td,
            job1td2:this.state.job1td2,
            job1td3:this.state.job1td3,
            job2td:this.state.job2td,
            job2td2:this.state.job2td2,
            job2td3:this.state.job2td3,
            job3td:this.state.job3td,
            job3td2:this.state.job3td2,
            job3td3:this.state.job3td3,



        }
    }
    insertData(){
        const db= this.state.db;
        const data= this.getAllInfo();

        set(ref(db, 'Resume/Title/'+data.name),
        {
            name:data.name,
            phone: data.phone,
            email:data.email,
            github:data.github,
            address:data.address,
            linkedin:data.linkedin,
            q1:data.q1,
            q2:data.q2,
            q3:data.q3,
            q4:data.q4,
            Languages:data.Languages,
            tools:data.tools,
            ed1:data.ed1,
            ed2:data.ed2,
            ed3:data.ed3,
            ed1sch:data.ed1sch,
            ed2sch:data.ed2sch,
            ed3sch:data.ed3sch,
            ed1schgpa:data.ed1schgpa,
            ed2schgpa:data.ed2schgpa,
            ed3schgpa:data.ed3schgpa,
            exp1:data.exp1,
            exp2:data.exp2,
            exp3:data.exp3,
            det1:data.det1,
            det2:data.det2,
            det3:data.det3,
            p1:data.p1,
            d1:data.d1,
            p2:data.p2,
            d2:data.d2,
            p3:data.p3,
            d3:data.d3,
            p4:data.p4,
            d4:data.d4,
            p5:data.p5,
            d5:data.d5,
            p6:data.p6,
            d6:data.d6,
            job1:data.job1,
            job2:data.job2,
            job3:data.job3,
            job1t:data.job1t,
            job2t:data.job2t,
            job3t:data.job3t,
            job1td:data.job1td,
            job1td2:data.job1td2,
            job1td3:data.job1td3,
            job2td:data.job2td,
            job2td2:data.job2td2,
            job2td3:data.job2td3,
            job3td:data.job3td,
            job3td2:data.job3td2,
            job3td3:data.job3td3,
        })
        .then(()=>{alert("data is added")})
        .catch((error)=>{alert("there is an error, details: ",+error)})
    }

    updateData(){
      const db= this.state.db;
      const data= this.getAllInfo();

      update(ref(db, 'Resume/Title/'+data.name),
      {
        name:data.name,
        phone: data.phone,
        email:data.email,
        github:data.github,
        address:data.address,
        linkedin:data.linkedin,
        q1:data.q1,
        q2:data.q2,
        q3:data.q3,
        q4:data.q4,
        Languages:data.Languages,
        tools:data.tools,
        ed1:data.ed1,
        ed2:data.ed2,
        ed3:data.ed3,
        ed1sch:data.ed1sch,
        ed2sch:data.ed2sch,
        ed3sch:data.ed3sch,
        ed1schgpa:data.ed1schgpa,
        ed2schgpa:data.ed2schgpa,
        ed3schgpa:data.ed3schgpa,
        exp1:data.exp1,
        exp2:data.exp2,
        exp3:data.exp3,
        det1:data.det1,
        det2:data.det2,
        det3:data.det3,
        p1:data.p1,
        d1:data.d1,
        p2:data.p2,
        d2:data.d2,
        p3:data.p3,
        d3:data.d3,
        p4:data.p4,
        d4:data.d4,
        p5:data.p5,
        d5:data.d5,
        p6:data.p6,
        d6:data.d6,
        job1:data.job1,
        job2:data.job2,
        job3:data.job3,
        job1t:data.job1t,
        job2t:data.job2t,
        job3t:data.job3t,
        job1td:data.job1td,
        job1td2:data.job1td2,
        job1td3:data.job1td3,
        job2td:data.job2td,
        job2td2:data.job2td2,
        job2td3:data.job2td3,
        job3td:data.job3td,
        job3td2:data.job3td2,
        job3td3:data.job3td3,
      })
      .then(()=>{alert("data is updated")})
      .catch((error)=>{alert("there is an error, details: ",+error)})
  }

  deleteData(){
    const db= this.state.db;
    const name= this.getAllInfo().name;

    remove(ref(db, 'Resume/Title/'+name))
 
    .then(()=>{alert("data is deleted")})
    .catch((error)=>{alert("there is an error, details: ",+error)})
}
selectData(){
  const dbref = ref(this.state.db);
  const username = this.getAllInfo().name;

  get(child(dbref,'Resume/Title/'+username )).then((snapshot)=>{
    if(snapshot.exists()){
      this.setState({
        name:snapshot.val().name,
        phone: snapshot.val().phone,
        email:snapshot.val().email,
        github:snapshot.val().github,
        address:snapshot.val().address,
        linkedin:snapshot.val().linkedin,
        q1:snapshot.val().q1,
        q2:snapshot.val().q2,
        q3:snapshot.val().q3,
        q4:snapshot.val().q4,
        Languages:snapshot.val().Languages,
        tools:snapshot.val().tools,
        ed1:snapshot.val().ed1,
        ed2:snapshot.val().ed2,
        ed3:snapshot.val().ed3,
        ed1sch:snapshot.val().ed1sch,
        ed2sch:snapshot.val().ed2sch,
        ed3sch:snapshot.val().ed3sch,
        ed1schgpa:snapshot.val().ed1schgpa,
        ed2schgpa:snapshot.val().ed2schgpa,
        ed3schgpa:snapshot.val().ed3schgpa,
        exp1:snapshot.val().exp1,
        exp2:snapshot.val().exp2,
        exp3:snapshot.val().exp3,
        det1:snapshot.val().det1,
        det2:snapshot.val().det2,
        det3:snapshot.val().det3,
        p1:snapshot.val().p1,
        d1:snapshot.val().d1,
        p2:snapshot.val().p2,
        d2:snapshot.val().d2,
        p3:snapshot.val().p3,
        d3:snapshot.val().d3,
        p4:snapshot.val().p4,
        d4:snapshot.val().d4,
        p5:snapshot.val().p5,
        d5:snapshot.val().d5,
        p6:snapshot.val().p6,
        d6:snapshot.val().d6,
        job1:snapshot.val().job1,
        job2:snapshot.val().job2,
        job3:snapshot.val().job3,
        job1t:snapshot.val().job1t,
        job2t:snapshot.val().job2t,
        job3t:snapshot.val().job3t,
        job1td:snapshot.val().job1td,
        job1td2:snapshot.val().job1td2,
        job1td3:snapshot.val().job1td3,
        job2td:snapshot.val().job2td,
        job2td2:snapshot.val().job2td2,
        job2td3:snapshot.val().job2td3,
        job3td:snapshot.val().job3td,
        job3td2:snapshot.val().job3td2,
        job3td3:snapshot.val().job3td3,

      })
    }
    else{
      alert("no data found")
    }
  })
  .catch((error)=>{alert("there is an error, details: "+error )})
}
}

